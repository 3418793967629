<template>
  <div class="status">
    <h1 style="color:white">Status Nodo {{ $route.params.dispenser_id }}</h1>
    <json-tree :data="status"></json-tree>
  </div>
</template>

<script>
import { ENV } from '../../config';
export default {
  data: () => ({
    status: {}
  }),
  created() {
    console.log(ENV !== 'production' 
  ? 'ws://'+location.host.split(':')[0]+':'+process.env.VUE_APP_API_PORT
  : 'ws://'+location.host.split(':')[0]);
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    rawStatus(data) {
      console.log('socket', data);
      if (data.dispenser_id == this.$route.params.dispenser_id) {
        this.status = data; 
      }
      //timer f5
      //this.reload = setTimeout(() => window.location.reload(),WINDOW_TIMEOUT);
    },
  },
}
</script>
